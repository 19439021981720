import React, {useState} from 'react'
import { CaretLeftOutlined  , CaretRightOutlined } from '@ant-design/icons';


export default function ContentCarousel() {
    const [index_vid, setindex_vid] = useState(0)
    const urls = ["bwW-oRFDxRk", "XaR39MO8JXc"]

    const onClickLeft = () => {
        if (index_vid > 0)
        {
            setindex_vid(index_vid-1)
        }
    }

    const onClickRight = () => {
        if (index_vid <  urls.length - 1)
        {
            setindex_vid(index_vid+1)
        }
    }


    return (
    <div className="carousel-content">
        <div className="skill-title" style={{top:"3%", left:"5%", position:"absolute"}}><span>S</span><span>a</span><span>m</span><span>s</span><span>u</span><span>n</span><span>g</span> <span>p</span><span>r</span><span>o</span><span>j</span><span>e</span><span>c</span><span>t</span></div>
        <div className="button-next" style={{left:"10%"}} onClick={onClickLeft}>
            <CaretLeftOutlined />
        </div>

        <div className="button-next" style={{right:"10%"}} onClick={onClickRight}>
            <CaretRightOutlined />
        </div>
        <div className="carousel-component">
            <iframe 
                className="video-carousel"
                width="900" 
                height="506" 
                src={`https://www.youtube.com/embed/${urls[index_vid]}`}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
                </iframe>
            </div>
    </div>
    )
}

