import React from 'react'
import Header from '../components/Header';
import ContentSkill from '../components/ContentSkill';
import ProjetContent from './ProjetContent';
import ContentCarousel from './ContentCarousel';

function Content() {
  return (
      <div className="Content">
          <Header/>
          <ContentSkill/>
          <ProjetContent/>
          <ContentCarousel/>
      </div>
  );
}

export default Content;