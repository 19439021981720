import React, {useState} from 'react'

function Card(props) {

const [flip, setflip] = useState(0)

const click = () => {
    console.log(flip)
    setflip(flip === 0 ? 1 : 0)
}

  return (
        <div className="flip-card"
        onClick={click}
        flip={flip}>
            <div className="flip-card-front">
                {props.icon}
                <h3 className="title">{props.title}</h3>
            </div>
            <div className={`flip-card-back`}>
                <div className={`card-header ${props.theme}`}></div>
                <div className="bottom-card">
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>
                </div>
            </div>
        </div>
  );
}

export default Card;