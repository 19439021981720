import React from 'react'
import { ArrowDownOutlined } from '@ant-design/icons';
import Logo from '../assets/logo5.svg'

function Header() {
  return (
      <div className="header">
        <div className="dino">
          <img src={Logo} alt="Logo"/>
        </div>
        <div className="text-pres">
            <div className="line"><span>H</span><span>i</span><span>!</span></div>
            <div className="line"><span>I</span><span>'</span><span>m</span> <span>T</span><span>h</span><span>o</span><span>m</span><span>a</span><span>s</span><span>,</span></div>
            <div className="line"><span>D</span><span>a</span><span>t</span><span>a</span> <span>S</span><span>c</span><span>i</span><span>e</span><span>n</span><span>t</span><span>i</span><span>s</span><span>t</span></div>
            <div className="typing">Computer vision / front developper</div>
            
        </div>

        <div className="btn">
            <svg className="svgButton" width="180px" height="60px" viewBox="0 0 180 60" class="border">
            <polyline points="179,1 179,39 1,39 1,1 179,1" class="bg-line" />
            <polyline points="179,1 179,39 1,39 1,1 179,1" class="hl-line" />
            </svg>
            <span>Conctact me!</span>
        </div>
        <div className="scroll-content-left">
          <ArrowDownOutlined style={{fontSize:"30px"}}/>
        </div>
        <div className="scroll-content-right">
          <ArrowDownOutlined style={{fontSize:"30px"}}/>
        </div>
      </div>
  );
}

export default Header;