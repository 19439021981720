import React from 'react'
import { UserOutlined  } from '@ant-design/icons';
import BarSkill from './BarSkill';
import KeywordSkill from './KeywordSkill';




function ContentSkill() {
  return (
    <div className="content-skills">
      <div className="content-personal">
        <div className="content-personal-title">
          <div className="icon-pres">
            <UserOutlined style={{color:"white", fontSize:"25px"}}/>
          </div>
            <div className="title-pres">Who am i?</div>
        </div>
          <div className="bubble">
             Thomas Gossin
          </div>
          <div className="bubble">
            I studied at Epita. 
          </div>
          <div className="bubble">
            I'm data-scientist with front-end development skills.
          </div>
          <div className="bubble">
            I currently work at Atos as a data scientist consultant. I work with the national institute of the audiovisual, as well as the general direction of the gendarmerie. 
          </div>
          <div className="bubbleRep">
            OK OK but what can you do?
          </div>
          <div className="bubble">
            I can do all the steps from building the model to putting it into production. I master data management and cleaning, python frameworks related to deep learning necessary for model creation, fastApi to consume the model, docker and in bonus an ihm to interact with the AI.
          </div>
      </div>
      <div className="content-skill-rate">
        <div className="skill-title"><span>S</span><span>k</span><span>i</span><span>l</span><span>l</span><span>s</span></div>

        <BarSkill name={"Python"} pc={90} color={"tomato"}/>
        <BarSkill name={"Machine learning"} pc={85} color={"cyan"}/>
        <BarSkill name={"Deep learning"} pc={80} color={"red"}/>
        <BarSkill name={"React"} pc={50} color={"salmon"}/>
        <BarSkill name={"Docker"} pc={45} color={"rgb(210, 108, 213)"}/>
        <BarSkill name={"Mlops"} pc={30} color={"rgb(255, 34, 83)"}/>

        <div className="keyword-content">
          <KeywordSkill name={"Tensorflow"}/>
          <KeywordSkill name={"Keras"}/>
          <KeywordSkill name={"Numpy"}/>
          <KeywordSkill name={"Pandas"}/>
          <KeywordSkill name={"Scipy"}/>
          <KeywordSkill name={"Sklearn"}/>
          <KeywordSkill name={"MlFlow"}/>
          <KeywordSkill name={"DVC"}/>
          <KeywordSkill name={"FastApi/Flask"}/>
          <KeywordSkill name={"C/C++"}/>
          <KeywordSkill name={"VBA"}/>
          <KeywordSkill name={"Arduino"}/>
          <KeywordSkill name={"Azure"}/>
          <KeywordSkill name={"Git"}/>
          <KeywordSkill name={"Linux"}/>


        </div>
      </div>
    </div>
  );
}

export default ContentSkill;