import React from 'react'

function BarSkill(props) {
  return (
    <div className="skill-element">
        <div className="skill-name">
            {props.name}
        </div>
        <div className="bar-skill">
            <div className="bar-skill-fill" style={{
                background: props.color,
                width: props.pc+'%'
            }}>

            </div>
        </div>
      </div>
  );
}

export default BarSkill;