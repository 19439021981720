import React from 'react'
import { SmileOutlined ,VideoCameraOutlined , PictureOutlined  ,LineChartOutlined  ,ScanOutlined} from '@ant-design/icons';
import Card from '../components/Card';

function ProjetContent() {
  return (
      <div className="projet-content">
          <div className="clickclick"> Click Click ! </div>
          <div className="skill-title" style={{margin:"70px"}}><span>P</span><span>r</span><span>o</span><span>j</span><span>e</span><span>c</span><span>t</span><span>s</span></div>
        <div className="container">
            <div className="card">
                <Card icon={<SmileOutlined style={{fontSize:"80px", color:"#4b4b4b"}}/>}
                title={"Emotion Classifier"}
                text={"Attention and emotion detection on video streams for the company AZEO"}
                theme="bb0"
                />
            </div>
            <div className="card">
                <Card icon={<VideoCameraOutlined style={{fontSize:"80px", color:"#4b4b4b"}}/>}
                    title={"Nonlinear unmixing of hyperspectral images"}
                    text={"Classification of materials using spectral lengths"}
                    theme="bb1"/>
            </div>
            <div className="card">
                <Card icon={<PictureOutlined style={{fontSize:"80px", color:"#4b4b4b"}}/>}
                    title={"Classification of paint by emotion"}
                    text={"Model of classification of painting by emotion for ArtIsYou art gallery"}
                    theme="bb4"/>
            </div>
            <div className="card">
                <Card icon={<LineChartOutlined style={{fontSize:"80px", color:"#4b4b4b"}}/>}
                    title={"Financial portfolio optimization"}
                    text={"2nd place in the portfolio optimization competition using machine learning organized by the company JUMP"}
                    theme="bb2"/>
            </div>
            <div className="card">
                <Card icon={<ScanOutlined style={{fontSize:"80px", color:"#4b4b4b"}}/>}
                    title={"Face recognition"}
                    text={"Implementation of a facial recognition system for the general direction of the gendarmerie"}
                    theme="bb3"/>
            </div>
        </div>
      </div>
  );
}

export default ProjetContent;