import React from 'react'
import Content from '../components/Content';

import Menu from '../components/Menu'


function Home() {
  return (
    <div className="home">
        <Menu/>
        <Content/>
    </div>
  );
}

export default Home;