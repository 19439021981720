import React from 'react'
import Logo from '../assets/logo2.svg'

function Menu() {
  return (
      <div className="menu">
          <div className="logo">
            <img src={Logo} className="myLogo" alt="Logo"/>
          </div>
          <span className='item-menu'></span>
          <span className='item-menu'>About</span>
          <span className='item-menu'>Skills</span>
          <span className='item-menu'>Projects</span>
          <span className='item-menu'>Demo</span>
          <span className='item-menu'>Contact</span>
      </div>
  );
}

export default Menu;