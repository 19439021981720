import Home from "./Pages/Home";
import 'antd/dist/antd.css';
import "./style/index.scss"

function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
